import { memo } from "react";
import "./Settings.scss";

const Settings = () => {
  return (
    <>
      <div>Settings</div>
    </>
  );
};

export default memo(Settings);
